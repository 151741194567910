<template>

    <swiper-slide class="h-full w-full overflow-auto no-scrollbar" style="width: 90%">

        <div class="h-full w-full absolute rounded-lg module p-2 flex flex-col z-30" style="transition: all 5s">

            <div class="h-8 flex flex-row justify-start items-center flex-none">
                <span class="text-sm font-semibold text-font-gray mr-4">Categories</span>
                <multiselect class="w-40" v-model="sortBySmu" placeholder="Select SMU" :options="smulElementsOrdered" label="full_name" :searchable="true"></multiselect>
            </div>

            <div v-if="!loadingcategories" class="flex flex-1 min-h-0 flex-col mt-2 overflow-auto no-scrollbar pb-6">

                <div class="box mb-2 flex flex-col pb-2" v-for="(el,index) in categories" :key="'--'+index">

                    <div class="h-6 flex flex-row justify-start items-center p-1">

                        <span class="text-xs font-semibold text-white">{{el.name}}</span>

                    </div>

                    <div class="h-6 flex flex-row justify-center items-center">

                        <div class="h-1 w-1 rounded-full bg-green mr-1"></div>

                        <span class="text-3xs mr-2 text-font-gray">Current Chargeability</span>

                        <span class="text-3xs mr-2 font-semibold">{{el.ytd | numberFormat(2)}}%</span>

                        <div class="h-1 w-1 rounded-full bg-purple mr-1"></div>

                        <span class="text-3xs mr-2 text-font-gray">Y-1 Chargeability</span>

                        <span class="text-3xs font-semibold">{{el.ytd_1 | numberFormat(2)}}%</span>

                    </div>

                    <div class="h-20">
                        <chart-area class="h-full w-full" chartId="nerChart" :data="[el.mtd, el.mtd_1]" :names="['Current Chargeability','Y-1 Chargeability']"></chart-area>
                        <!-- <chart-area chartId="nerChart" :data="randomChart()"></chart-area> -->
                    </div>

                </div>

            </div>

            <div v-else class="h-full relative">

                <loader :loading="loadingcategories" />

            </div>

        </div>

    </swiper-slide>

</template>

<script>

import { state, actions } from '@/store';

export default {
    props:['smuElements'],
    data(){
        return{
            loadingcategories:false,
            categories:false,
            sortBySmu: false,
            smufiltereds: false,
            sortBy:{name:'Name', value: 'name', order: 'asc'},
        }
    },
    methods:{
        preload() {
            this.loadingcategories = true
        },
        load(){

            let paramCategories = {}

            if(this.slSelectedItems.length > 0){
                
                if(!this.sortBySmu){
                    paramCategories = {
                        ...this.params,
                        sl_zeus:this.slSelectedItems
                    }
                } else {
                    
                    let param = this.sortBySmu.id.split('_')

                    paramCategories = {
                        ...this.params,
                        sl_zeus: [param[0]],
                        competencyIdWithRegionId:[param[1]]
                    }
                }

            } else {
                
                paramCategories = {
                    ...this.params,
                    sl_zeus:null
                }

            }

            this.loadingcategories = true;
            
            this.axios.get('chargeability/rankytd', { params: paramCategories }).then(response => {
                this.categories = response.data.data
                this.loadingcategories = false
            })

        }
    },
    computed:{
        params(){ 
            return state.globalParams 
        },
        slSelectedItems(){ 
            return state.slSelectedItems 
        },
        smulElementsOrdered(){

            let selecteds = []
            let smuFiltereds = []

            if(this.smuElements && this.smuElements.length > 0){

                for (let index = 0; index < this.smuElements.length; index++) {

                    if(this.slSelectedItems.includes(this.smuElements[index].id_sl)){

                        selecteds.push(this.smuElements[index])
                        smuFiltereds.push(this.smuElements[index].id)

                    }

                }

            }

            this.smufiltereds = smuFiltereds

            return this._.orderBy(selecteds, [this.sortBy.value])

        },
    },
    watch:{
        slSelectedItems(){
            this.load();
        },
        sortBySmu(){
            this.load();
        }
    },
    mounted(){
        this.preload();
    }
}
</script>